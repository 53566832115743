import request from '../utils/request'

import axios from 'axios';
import global from "@/plugins/global";

const apiUrl = global.getServerapiUrl()
export function sendContactUs(data) {
	return request({
	  url: '/api/webContactUs/add',///api/webStoreInfo/W
	  method: 'post',
	  data: data,
	  headers: {
	        'Content-Type': 'application/json',
	        'accept': '*/*'
	      }
	})
}

// 门店列表 ß
export function getStoreList (query) {
  return request({
    url: '/api/webStoreInfo/list',///api/webStoreInfo/W
    method: 'get',
    params: query
  })
}