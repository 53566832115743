var serverapiUrl=''
// const serverapiUrl='http://www.lczrxb.com/prod-api'
var protocol = window.location.protocol; // 获取当前页面使用的协议

function getServerapiUrl () {
	if (protocol === "http:") {
	    console.log("当前域名是HTTP");
		serverapiUrl='http://www.lczrxb.com/prod-api'
	} else if (protocol === "https:") {
	    console.log("当前域名是HTTPS");
		serverapiUrl='https://www.lczrxb.com/prod-api'
	}
  return serverapiUrl
}
module.exports = {
	getServerapiUrl
}
